import { useContext } from "react";
import { useQuery } from "react-query";
import { ReportSerializer } from "../backend-types";
import { config } from "../config";
import ErContext from "../ErContext";

/*******************************************
 *** Types
 ******************************************/
interface ReportsConfig extends ReportSerializer {
  iframe_url: string
  reports_base_url: string
  dashboard_path: string
  logout_url: string
}

/*******************************************
 *** Constants
 ******************************************/
export const reportsConfigIdent = () => "reports_config";

/*******************************************
 *** Fetcher
 ******************************************/
const fetchReportsConfig = (authToken: string) => async () => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);
  return await (await fetch(`${config.backendBaseUrl}/api/reports/config/`, {
    headers
  })).json()
}

/*******************************************
 *** Export
 ******************************************/
export const useReportsConfig = () => {
  const { state: { authToken } } = useContext(ErContext)
  const { data } = useQuery<ReportsConfig>(
    reportsConfigIdent(), fetchReportsConfig(authToken!),
    // this should never be refetched, or it will reload the reports iframe
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  return data
};
