import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Title from "./Title.svg"
import Avatar from "./Avatar"
import ErContext from '../ErContext'
import { Link } from "react-router-dom";
import { logout } from "../actions"
import Notifications from './Notifications'
import { useReportsConfig } from '../hooks/useReportsConfig'

const NavBar = () => {
  const context = useContext(ErContext)
  const config = useReportsConfig()
  const signOut = () => {
    logout(config?.logout_url)
  }

  return (
    <div className="bg-brand-dark w-full">
      <div className="mx-auto px-2">
        <div className="relative flex items-center justify-between h-14">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="h-10 w-auto"
                src={Title}
                alt="Workflow"
              />
              <div className="ml-6 mr-6 text-lg text-white font-medium truncate">
                Emergency Response
              </div>
            </div>
            <div className="h-14 pt-5 px-2 mx-5 text-white opacity-70 text-md font-semibold border-b-4 border-opacity-0 transform transition ease-in-out duration-600 hover:opacity-100 hover:border-opacity-100 hover:border-blue-400">
              <Link to="/er/predictions">Predictions</Link>
            </div>
            <div className="h-14 pt-5 px-2 mx-5 text-white opacity-70 text-md font-semibold border-b-4 border-opacity-0 transform transition ease-in-out duration-600 hover:opacity-100 hover:border-opacity-100 hover:border-blue-400">
              <Link to="/er/reports">Reports</Link>
            </div>
          </div>


          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">


            {/* Notifications dropdown */}
            <Notifications />

            {/* Profile dropdown */}
            <Menu as="div" className="ml-5 relative">
              <div>
                <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                  <Avatar name={context.state.userInfo?.name}></Avatar>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#profile"
                            className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item> */}
                  <Menu.Item onClick={signOut}>
                    {({ active }) => (
                      <span className={`block px-4 py-2 text-sm text-gray-700 cursor-pointer ${active ? 'bg-gray-100' : ''}`}>
                        Sign out
                      </span>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  )
}


export default NavBar