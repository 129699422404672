import React, { useContext, useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './api/api';
import ErContext, { erContextDefault, TErContext, UserInfo } from "./ErContext"
import { useGetResourceFactor } from './hooks/useGetResourceFactor';
import PredictionsScreen from './screens/PredictionsScreen';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import ReportsScreen from './screens/ReportsScreen';
import { factory } from './service/factory';
import { RefreshIcon } from '@heroicons/react/outline';


const InnerApp = () => {
  const { actions: { setResourceFactor } } = useContext(ErContext)

  const latestResourceFactor = useGetResourceFactor()

  // Sorry for this
  useEffect(() => {
    if (latestResourceFactor?.call_to_allocation_factor !== undefined) {
      setResourceFactor(latestResourceFactor.call_to_allocation_factor)
    }
  }, [latestResourceFactor])

  return (
    <Router>
      <div className="h-screen max-h-screen flex flex-col justify-between">
        <Routes>
          <Route path="/predictions" element={<Navigate replace to="/er/predictions" />} />
          <Route path="/reports" element={<Navigate replace to="/er/reports" />} />
          <Route path="/er/predictions" element={<PredictionsScreen />} />
          <Route path="/er/reports" element={<ReportsScreen />} />
          <Route path="*" element={<Navigate replace to="/er/predictions" />} />
        </Routes>
      </div>
    </Router>
  )
}

const AuthWrapper = () => {
  const [erStateAuth, setErStateAuth] = useState<TErContext["state"]["authToken"]>(erContextDefault.state.authToken)
  const [erStateRes, setErStateRes] = useState<TErContext["state"]["resourceFactor"]>(erContextDefault.state.resourceFactor)
  const [erStateUsr, setErStateUser] = useState<TErContext["state"]["userInfo"]>(erContextDefault.state.userInfo)
  const [erStaleSince, setErStaleSince] = useState<TErContext["state"]["staleSince"]>(erContextDefault.state.staleSince)

  const erCtx: TErContext = {
    state: {
      authToken: erStateAuth,
      resourceFactor: erStateRes,
      userInfo: erStateUsr,
      staleSince: erStaleSince
    },
    actions: {
      setResourceFactor: (n: number) => setErStateRes(n),
      setAuthToken: (token: string) => setErStateAuth(token),
      setUser: (user: UserInfo) => setErStateUser(user),
      setLastUpdate: (d) => setErStaleSince(d)
    }
  }

  useEffect(() => {
    factory.authService().initialize(erCtx)
  }, [])

  return (<ErContext.Provider value={erCtx}>
    {erCtx.state.authToken !== null ? (
      <InnerApp></InnerApp>) : (<div className="bg-brand-dark w-screen h-screen fixed flex items-center justify-center">
        <RefreshIcon className="animate-spin-slow text-white opacity-33 w-60" />
      </div>)
    }
  </ErContext.Provider>)
}

const App = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthWrapper />
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
