import { useContext } from 'react';
import { useQuery } from 'react-query';
import { config } from '../config';
import ErContext from '../ErContext';

export const useGetModelRuns = (): string[] => {
  const { state: { authToken } } = useContext(ErContext)
  const { data } = useQuery(["model_runs"], async () => {
    const headers = new Headers({
      'Authorization': `Bearer ${authToken}`
    })
    return await (await fetch(`${config.backendBaseUrl}/api/model_runs/`, {
      headers
    })).json()
  })

  return data?.results ?? []
}

