import { config } from "./config";
import { factory } from "./service/factory";

export const saveResouceFactor = async (resourceFactor: number, authToken: string) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${authToken}`
  });
  await fetch(`${config.backendBaseUrl}/api/resource_factor/set_resource_calculation/`, {
    body: JSON.stringify({
      factor: resourceFactor
    }),
    method: "POST",
    headers
  })
}

export const dismissNotification = async (nId: number, authToken: string) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);
  await fetch(`${config.backendBaseUrl}/api/notifications/${nId}/dismiss/`, {
    method: "POST",
    headers
  })
}

export const markNotificationSeen = async (nId: number, authToken: string) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);
  await fetch(`${config.backendBaseUrl}/api/notifications/${nId}/mark_seen/`, {
    method: "POST",
    headers
  })
}

export const logout = async (reportsLogoutUrl?: string) => {
  // Make sure we are logged out from Superset
  if (reportsLogoutUrl) {
    const ifrm = document.createElement("iframe")
    ifrm.setAttribute("src", reportsLogoutUrl)
    ifrm.style.width = "1px";
    ifrm.style.height = "1px";
    document.body.appendChild(ifrm)
  }
  // Logout
  factory.authService().logout()
}