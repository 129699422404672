import { config } from "../config"

const BottomBar = (p: { predictionDate?: Date }) => {
  const dateFormatter = Intl.DateTimeFormat(config.locale)
  const timeFormatter = Intl.DateTimeFormat(config.locale, { timeStyle: "short" })
  const string = p.predictionDate ? `Prediction updated ${dateFormatter.format(p.predictionDate)} at ${timeFormatter.format(p.predictionDate)}` : ""

  // const context = useContext(ErContext)

  return (
    <div className="bg-gray-100 z-20 w-full text-gray-800 border-t-2 text-right p-1 pr-2 text-sm">
      {/* Resource factor: {context.state.resourceFactor} */}
      {/* Logged in user:  {context.state.userInfo.name} */}
      {string}
    </div>
  )
}

export default BottomBar