/*******************************************
 *** Fetcher
 ******************************************/

import { useContext } from "react";
import { useQuery } from "react-query";
import { config } from "../config";
import ErContext from "../ErContext";

const fetchPredictionsTable = (authToken: string, from?: string, to?: string) => async () => {
  if (to === undefined) {
    return
  }
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);
  return await (await fetch(`${config.backendBaseUrl}/api/predictions/indexed_diff/?to=${to}${from ? `&from=${from}` : ""}`, {
    headers
  })).json()
}

/*******************************************
 *** Export
 ******************************************/

export const useGetPredictionsIdx = (from: string | undefined, to: string | undefined): { data: TableIdx<TablePayload>, isLoading: boolean } => {
  const { state: { authToken, staleSince } } = useContext(ErContext)
  const { data } = useQuery<TableIdx<TablePayload> | undefined>(["useGetPredictionsIdx", from, to, staleSince], fetchPredictionsTable(authToken!, from, to))
  return { data: data ?? {}, isLoading: data === undefined && (from !== undefined) }
}

