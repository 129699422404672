import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ResourceAllocationSerializer } from '../backend-types';
import { config } from '../config';
import ErContext from '../ErContext';

/*******************************************
 *** Constants
 ******************************************/

const resourceFactorIdent = () => "resourceFactor"

/*******************************************
 *** Fetcher
 ******************************************/

export const fetchResourceFactor = (authToken: string) => async () => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);
  return await (await fetch(`${config.backendBaseUrl}/api/resource_factor/latest/`, {
    headers
  })).json()
}

/*******************************************
 *** Export
 ******************************************/

export const useGetResourceFactor = () => {
  const { state: { authToken } } = useContext(ErContext)
  const { data } = useQuery<ResourceAllocationSerializer | undefined>(
    resourceFactorIdent(),
    fetchResourceFactor(authToken!), {
    refetchInterval: false,
    refetchOnWindowFocus: false
  })

  return data
}

