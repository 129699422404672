import { ChevronLeftIcon, ChevronRightIcon, SearchIcon, UsersIcon, XCircleIcon } from '@heroicons/react/outline';
import { PhoneIcon } from "@heroicons/react/solid";
import { useEffect, useRef, useState } from 'react';
import { Row, TableInstance, useAsyncDebounce } from "react-table";
import ReactTooltip from 'react-tooltip';
import { Disclosure } from "@headlessui/react";
import { cloneDeep } from 'lodash';

type PredictionsTableProps = {
  showShifts: boolean
  horizontalPage: number
  maxPage: number
  setMaxPage: (n: number) => void
  flipPage: (p: "left" | "right") => void
  predictions: TableIdx<TablePayload>,
  isLoading: boolean
  tableInstance: TableInstance<{
    [col: string]: {
      "from_calls": number
      "from_ftes": number
      "to_calls": number
      "to_ftes": number
    } | string | number
  }>
}

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, }) => {
  const [value, setValue] = useState(globalFilter)

  useEffect(() => {
    setValue(globalFilter)
  }, [globalFilter])

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className="relative flex w-full flex-wrap items-stretch">
      <span
        className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400  bg-transparent rounded text-base items-center justify-center w-7 pl-2 py-1">
        <SearchIcon></SearchIcon>
      </span>
      <input
        value={value || ''}
        type="text"
        placeholder="Search territories..."
        onChange={e => { setValue(e.target.value); onChange(e.target.value) }}
        className="px-2 py-1 text-gray-600 relative bg-gray-100  rounded-lg text-sm border-0 outline-none focus:outline-none w-full pl-10" />
      <span
        className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400  bg-transparent rounded text-base items-center justify-center w-7 right-0 pr-2 py-1">
        {value ? <XCircleIcon className="cursor-pointer" onClick={() => { setValue(undefined); onChange(undefined) }}></XCircleIcon> : <></>}
      </span>
    </div>
  )
}


const PredictionsTable = ({ showShifts, horizontalPage, setMaxPage, predictions, tableInstance, flipPage, maxPage, isLoading }: PredictionsTableProps) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const outerContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [predictions])

  // Handle paging. Ie. set max page and execute scrolling.
  // This should be split out and the handling of max page should take resizing
  // into consideration
  useEffect(() => {
    if (outerContainer?.current) {
      // Each page is only 80% to create a small overlap
      const pageWidth = outerContainer?.current.clientWidth * 0.8
      outerContainer.current.scrollTo({ behavior: 'smooth', left: horizontalPage * pageWidth })
      const maxPage = Math.ceil(outerContainer?.current.scrollWidth / pageWidth)
      setMaxPage(maxPage - 1)
    }
  }, [showShifts, horizontalPage, setMaxPage])


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    setGlobalFilter,
  } = tableInstance

  const pRow = (rows: Array<Row<{
    [col: string]: string | number | {
      from_calls: number;
      from_ftes: number;
      to_calls: number;
      to_ftes: number;
    };
  }>>, first = true) => {
    const row = rows.shift()
    if (row === undefined) {
      return <></>
    }

    const indent = row?.original?.indent

    // First index where indent is hiher than current
    let index = rows.findIndex(e => e.original?.indent <= indent)
    index = index === -1 ? rows.length : index

    // Split into children and siblings
    const children = rows.slice(0, index)
    const siblings = rows.slice(index)

    prepareRow(row);

    const ThisRow = <div className={`_tr flex items-center group ${indent === 0 && !first ? "border-t-2 border-gray-200" : ""}`} {...row.getRowProps()}>
      {row.cells.map((cell) => {
        const isTotal = cell.column.id.endsWith("total")
        const leftCol = cell.column.id === "segment"
        const isDivideCell = isTotal || leftCol || !showShifts

        return (
          <div className={`_td border-solid h-10 group-hover:bg-blue-50 ${leftCol && horizontalPage > 0 ? "right-shadow-show" : "right-shadow-hide"} ${leftCol ? "right-shadow sticky left-0" : ""} ${isTotal ? "bg-gray-100 bg-opacity-50 bg-blend-darken" : "bg-white"} ${isDivideCell ? "border-r-2" : "border-r"}`}
            {...cell.getCellProps()}>
            {cell.render("Cell")}
          </div>
        )
      })}
    </div>

    // 2 is the only one with no immidate children
    if (indent === 2) {
      return (<>
        {ThisRow}
        {pRow(rows, false)}
      </>)
    }

    return <>
      <Disclosure defaultOpen={true}>
        {ThisRow}
        <Disclosure.Panel>
          {pRow(children, false)}
        </Disclosure.Panel>
      </Disclosure>
      {pRow(siblings, false)}
    </>
  }

  const clonedRows = cloneDeep(rows)

  const allRows = pRow(clonedRows)
  const isEmpty = rows.length === 0

  const disableLeft = horizontalPage <= 0
  const disableRight = horizontalPage >= maxPage

  const EmptySearch = (<div className="w-screen text-center overflow-hidden sticky left-0">
    <div className="text-xl text-gray-700 mt-10 mb-5">
      No territory matches for "{state.globalFilter}".
    </div>

    <button className="font-semibold border-2 p-1 rounded-md text-gray-700" onClick={() => setGlobalFilter(undefined)}>Clear search</button>
  </div>)

  const NoData = (<div className="w-screen text-center overflow-hidden sticky left-0">
    <div className="text-xl text-gray-700 mt-10 mb-5">
      There is no data to display.
    </div>

    <div className="text-gray-600 font-light">Please contact your administrator for help.</div>
  </div>)

  const LoadingData = (<div className="w-screen text-center overflow-hidden sticky left-0">
    <div className="text-xl text-gray-700 mt-10 mb-5">
      Loading data.
    </div>

    <div className="text-gray-600 font-light">Please stay tuned.</div>
  </div>)

  return (
    <>
      <div className="w-screen absolute top-1/2 flex invisible">
        <div onClick={() => flipPage("left")} className={`${disableLeft ? "invisible" : "visible"} sticky top-1/2 left-88 w-7  p-1 z-10 bg-white border shadow-md rounded-full cursor-pointer`}>
          <ChevronLeftIcon className="w-4 h-4" />
        </div>
        <div onClick={() => flipPage("right")} className={`${disableRight ? "invisible" : "visible"} sticky top-1/2 left-full mr-6 w-7 p-1 z-10 bg-white border shadow-md rounded-full cursor-pointer`}>
          <ChevronRightIcon className="w-4 h-4" />
        </div>
      </div>
      <div ref={outerContainer} className="flex-grow grid overflow-x-hidden content-start bg-gray-50" {...getTableProps()}>
        <div className="_thead bg-white z-10 sticky top-0 border-b-2">
          {headerGroups.map((headerGroup) => (
            <div className="_tr" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const isTotal = column.id.endsWith("total")
                const leftCol = column.id === "segment" || column?.["showFilter"]
                const isDivideCell = isTotal || leftCol || !showShifts
                return (
                  <div
                    className={`_th h-13 pt-1 px-4 transition-width ${leftCol ? "sticky left-0 bg-white" : ""} ${isTotal ? "bg-gray-50" : ""} ${isDivideCell ? "border-r-2" : "border-r"}`}
                    {...column.getHeaderProps()}
                  >
                    {column?.["showFilter"] ? <GlobalFilter
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    /> : null}
                    {column.render("Header")}
                  </div>
                )
              })}
            </div>
          ))}
        </div>


        {!isEmpty
          ? <div className="_tbody" ref={parentRef} {...getTableBodyProps()}>{allRows}</div>
          : isLoading
            ? LoadingData
            : !state.globalFilter
              ? NoData
              : EmptySearch}
      </div>

      {/* {isEmpty ?  : <></>} */}

      <ReactTooltip
        id='cell-tooltip'
        effect="solid"
        backgroundColor="transparent"
        border={false}
        place="bottom"
        offset={{ top: 5 }}
        getContent={(tip) => ToolTip({ tip })}
      />

    </>
  );
}

const ToolTip = (p: { tip: string }) => {
  let pObj = {}

  try {
    pObj = JSON.parse(p.tip)
  } catch (e) { }

  const hasWasCalls = pObj?.["from_calls"] !== undefined && pObj?.["from_calls"] !== pObj?.["to_calls"]
  const hasWasFtes = pObj?.["from_ftes"] !== undefined && pObj?.["from_ftes"] !== pObj?.["to_ftes"]

  const bgCalls = pObj?.["from_calls"] > pObj?.["to_calls"] ? "text-green-700" : "text-yellow-700"

  const resources = pObj?.["to_ftes"] ?? "-"
  const prevResources = pObj?.["from_ftes"] ?? "-"

  const calls = pObj?.["to_calls"] ?? "-"
  const previouscalls = pObj?.["from_calls"] ?? "-"

  return (<div className={`flex p-1 px-3 border-2 shadow-lg rounded-md text-black bg-white opacity-1000 ${hasWasCalls ? "w-72" : "w-40"}`}>
    <div className="w-1/2 text-center p-1 border-r-2 ">
      <PhoneIcon className="w-4 h-4 mr-2 inline" />
      <span className={`font-bold text-md mr-2 ${hasWasCalls ? bgCalls : ""}`}>{calls}</span>
      {hasWasCalls ? <span className="text-gray-700">(Was {previouscalls})</span> : ""}
    </div>
    <div className="w-1/2 text-center p-1">
      <UsersIcon className="w-4 h-4 mr-2 inline" />
      <span className={`font-bold text-md mr-2 ${hasWasFtes ? bgCalls : ""}`}>{resources}</span>
      {hasWasFtes ? <span className="text-gray-700">(Was {prevResources})</span> : ""}
    </div>
  </div>)
}

export default PredictionsTable
