import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useContext } from "react"
import { saveResouceFactor } from "../actions"
import ErContext from "../ErContext"

const ResourceAllocationModal = (p: { open: boolean, setOpen: (n: boolean) => void }) => {
  const context = useContext(ErContext)

  const doSaveResourceAllocation = async () => {
    await saveResouceFactor(context.state.resourceFactor, context.state.authToken!)
    context.actions.setLastUpdate(new Date())
    p.setOpen(false)
  }

  return (<Transition.Root show={p.open} as={Fragment}>
    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => p.setOpen(false)}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">

            <div className="bg-white px-6 pt-5 pb-4 border-b-2">
              <Dialog.Title as="h1" className="text-xl leading-6 font-bold text-gray-900">
                Resource allocation
              </Dialog.Title>
            </div>

            <div className="bg-white px-6 pt-5 pb-4">
              <div className="mt-3">

                <div className="mt-2">
                  <label>
                    <div>Assign resource for every...</div>
                    <input
                      className="border p-2 mt-2 rounded-lg border-gray-300 focus:rounded-lg focus:border-gray-500 focus:outline-none"
                      type="number"
                      value={context.state.resourceFactor}
                      onChange={n => context.actions.setResourceFactor(Number(n.target.value))} />
                  </label>
                </div>
              </div>
            </div>

            <div className="px-6 py-5">
              <button
                type="button"
                className="rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={doSaveResourceAllocation}
              >
                Save changes
              </button>

              <button
                type="button"
                className="ml-4 rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-bold text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={() => p.setOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>)
}

export default ResourceAllocationModal
