import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { config } from '../config'

const TitleBar = (p: { maxPage: number, page: number, flipPage: (p: "left" | "right" | "home") => void }) => {

  const disableLeft = p.page <= 0
  const disableRight = p.page >= p.maxPage

  const today = new Date()
  const forthnight = (new Date())
  forthnight.setDate(today.getDate() + 14)

  const monthFormatter = new Intl.DateTimeFormat(config.locale, { month: "short" })
  const yearFormatter = new Intl.DateTimeFormat(config.locale, { year: "numeric" })
  const dateFormtter = new Intl.DateTimeFormat(config.locale, { day: "2-digit" })

  return (
    <div className="flex">
      {/* Use text-gray-200 for the inactive button */}
      <button type="button" disabled={disableLeft} className={`transform transition ease-in-out duration-600 ${disableLeft ? "text-gray-200" : "text-gray-600"}`} onClick={() => p.flipPage("left")}>
        <ChevronLeftIcon className="h-5 w-5 mx-1"></ChevronLeftIcon>
      </button>
      <button type="button" disabled={disableRight} className={`transform transition ease-in-out duration-600 ${disableRight ? "text-gray-200" : "text-gray-600"}`} onClick={() => p.flipPage("right")}>
        <ChevronRightIcon className="h-5 w-5 mx-1"></ChevronRightIcon>
      </button>
      <h1 className="font-bold text-lg p-2 ">
        {monthFormatter.format(today)} {dateFormtter.format(today)} - {monthFormatter.format(forthnight)} {dateFormtter.format(forthnight)} {yearFormatter.format(forthnight)} Predictions
      </h1>

      <button className={`${disableLeft ? "invisible" : "visible"} border border-gray-300 bg-gray-100 text-gray-700 rounded-md px-3 my-1 text-sm font-semibold`} onClick={() => p.flipPage("home")}>
        Today
      </button>
    </div>
  )
}

export default TitleBar