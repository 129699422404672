import { createContext } from "react";

export type UserInfo = {
  id: string
  name: string
  email: string
  role_name: "urbint-admin" | "admin" | "manager" | "viewer",
  permissions: string[]
}

export type TErContext = {
  state: {
      resourceFactor: number
      userInfo: UserInfo | null
      authToken: string | null
      
      // When we last updated stuff from the backend
      staleSince: Date
  };
  actions: {
      setResourceFactor: (n: number) => void
      setAuthToken: (n: string) => void
      setUser: (user: UserInfo) => void
      setLastUpdate: (date: Date) => void
  };
}

export const erContextDefault: TErContext = {
  state: {
    resourceFactor: NaN,
    userInfo: null,
    authToken: null,

    staleSince: new Date()
  },
  actions: {
    setResourceFactor: (n: number) => { },
    setAuthToken: (n: string) => {},
    setUser: (user: UserInfo) => {},
    setLastUpdate: (date: Date) => {}
  }
}

const ErContext = createContext<TErContext>(erContextDefault);

export default ErContext