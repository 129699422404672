const colors = [
  "bg-indigo-600 text-white",
  "bg-indigo-900 text-white",
  "bg-blue-600 text-white",
  "bg-blue-800 text-white",
  "bg-yellow-600 text-white",
  "bg-yellow-900 text-white",
  "bg-green-600 text-white",
  "bg-green-900 text-white",
  "bg-bluegray-800 text-white",
  "bg-pink-800 text-white",
  "bg-pink-600 text-white",
];

// Consistent hashing function for converting a name
// to one of the background colors.
const nameToColor = (name: string) => {
  let sum = 0;
  for (let i = 0; i < name.length; ++i) {
    sum += name.charCodeAt(i);
  }

  return colors[sum % colors.length];
};

// Unfortunately our user model doesn't have a First Name / Last Name
// field, so our best attempt is to split the name on the " " and use that.
const nameToInitials = (name: string) => {
  if (name === "") return "??";
  let parts = name.trim().split(" ");
  if (parts.length < 2) {
    // See if we get better results by splitting on `.`
    // Check first whether we have an email, and if so, only
    // look at the first part
    let str = name;
    if (str.match(/@/)) {
      str = str.split("@")[0]!;
    }
    const dotParts = str.split(".");
    if (dotParts.length > parts.length) {
      parts = dotParts;
    }
  }
  let initials;
  if (parts.length === 1) {
    initials = parts[0]!.substr(0, 2);
  } else {
    const lastIdx = parts.length - 1;
    initials = (parts[0]![0] || "?") + (parts[lastIdx]![0] || "?");
  }
  return initials.toUpperCase();
};


const Avatar = ({ name }: { name?: string }) => {
  return (
    <div
      className={`${name && nameToColor(name)} h-8 w-8 rounded-full flex items-center justify-center relative isolate overflow-hidden`}
    >
      <p
        className="w-full text-center text-md font-semibold"
        data-testid="task-card-assignee"
      >
        {name && nameToInitials(name)}
      </p>
    </div>
  );
};

export default Avatar