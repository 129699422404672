import { isNumber } from "lodash";
import { UserInfo } from "./ErContext";
import { DatePrediction, } from "./types";

type Permissions = 'EDIT_RESOURCE_FACTOR' | 'SUPERSET_SQL_LAB' | 'SUPERSET_DATA_IMPORT' | 'SUPERSET_ADMIN'

/**
 * Function that derives from the role is a user can update resource allocation
 * 
 * TODO: This should be factored into permissible events.
 * 
 * @param u 
 * @returns 
 */
export const hasPermissionTo = (u: UserInfo | undefined | null, perm: Permissions) => {
  if (!u) {
    return false
  }
  return u?.permissions?.includes(perm) ?? false
}

export const range = (start: number, end: number) => {
  return [...Array(end - start).keys()].map(i => i + start);
}

export const callsToResources = (calls: number, factor: number) => {
  return Math.ceil(calls / factor)
}

/**
 * Summ over all shifts in a date prediction.
 * 
 * If all are unknown, then the result is 0
 * 
 * @param day 
 * @returns 
 */
export const daySum = (day: DatePrediction) => {
  const a: number = isNumber(day["DAY"]) ? Number(day["DAY"]) : 0
  const b: number = isNumber(day["EVENING"]) ? Number(day["EVENING"]) : 0
  const c: number = isNumber(day["OVERNIGHT"]) ? Number(day["OVERNIGHT"]) : 0

  return a + b + c
}

export const dayDiff = (a: Date, b: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
}

/**
 * Get date index
 * 
 * In the table view we uase relative IDs for columns such as date-0 for today etc.
 * This function calculates the index from todays date and a future date
 * 
 * @param today 
 * @param date 
 */
export const dateToIdx = (today: Date, date: Date) => {
  return `${dayDiff(today, date)}`
}

export const shiftToIdx = (shift: string) => {
  switch (shift) {
    case "DAY":
      return 0
    case "EVENING":
      return 1
    case "OVERNIGHT":
      return 2

    default:
      throw new Error(`Could not recognize shift ${shift}`);
  }
}
