import { Menu, Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/outline'
import { Fragment, useContext, useState } from 'react'
import { markNotificationSeen } from '../actions'
import ErContext from '../ErContext'
import { useGetNotifications } from '../hooks/useGetNotifications'
import UpdatedPredictionNotification from './notifications/UpdatedPredictionNotification'
import UpdatedResourceFactor from './notifications/UpdatedResourceFactor'

const Notifications = () => {
  const [lastDismissed, setLastDismissed] = useState(new Date().getTime())
  const notifications = useGetNotifications(lastDismissed)

  const context = useContext(ErContext)

  const seeNotification = (id: number) => {
    return () => markNotificationSeen(id, context.state.authToken!).then(() => setLastDismissed(new Date().getTime()))
  }

  return <Menu as="div" className="ml-3 relative">
    <Menu.Button
      type="button"
      className="p-1 rounded-full text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
    >
      {notifications.filter(n => n.notification_state !== "SEEN").length > 0 ? <span className="text-blue-500 absolute bottom-3">●</span> : null}
      <span className="sr-only">View notifications</span>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right w-440px z-50 absolute right-0 mt-5 rounded-md shadow-lg pb-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="w-full bg-gray-100 p-2 text-sm font-semibold rounded-t-md">
          Notifications
        </div>

        <div className="overflow-y-scroll h-screen/2">
          {
            notifications.length === 0 && false ?
              <Menu.Item>
                {() => {
                  return <div className="m-5 text-center font-semibold text-gray-500">No new notifications</div>
                }}
              </Menu.Item> :
              notifications.map(notification => {
                let Cmp = ({ active }: { active: boolean }) => <div>{notification.notification_type}</div>
                switch (notification.notification_type) {
                  case "PREDICTION_UPDATE":
                    Cmp = ({ active }: { active: boolean }) => <UpdatedPredictionNotification active={active} notification={notification} onClick={seeNotification(notification.id!)}></UpdatedPredictionNotification>
                    break
                  case "RESOURCE_FACTOR_UPDATE":
                    Cmp = ({ active }: { active: boolean }) => <UpdatedResourceFactor active={active} notification={notification} onClick={seeNotification(notification.id!)}></UpdatedResourceFactor>
                }

                return (<Menu.Item key={notification?.id!}>
                  {({ active }) => {
                    return <div>
                      <Cmp active={active} />
                    </div>

                  }}
                </Menu.Item>)
              })}
        </div>
      </Menu.Items>

    </Transition>
  </Menu>
}

export default Notifications

