export type DatePrediction = {
  [t: string]: number | "<unknown>"
}

export const isTablePayload = (v: any): v is TablePayload => {
  if (v?.["from_calls"] !== undefined &&
    v?.["from_ftes"] !== undefined &&
    v?.["to_calls"] !== undefined &&
    v?.["to_ftes"] !== undefined) {
    return true
  }
  return false
}