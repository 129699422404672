import NavBar from "../components/NavBar"
import { useReportsConfig } from "../hooks/useReportsConfig"

const ReportsScreen = () => {
  const config = useReportsConfig()

  const url = config?.iframe_url

  if (url !== undefined) {
    return (
      <>
        <NavBar></NavBar>
        <iframe
          title="Reports"
          allowFullScreen={true}
          src={url}
          className="w-full h-full"
        ></iframe>
      </>)
  }
  return <>
    <div>Initializing</div>
  </>
}

export default ReportsScreen