const f = (tableIdx: TableIdx<TablePayload>, hierarchy?: OrgTree, indent = 0, sortKeySuffix = ""): TableIdx<TablePayload> => {
  if (!hierarchy) {
    return {}
  }

  let ret: TableIdx<TablePayload> = {}
  let sortKey = 0

  for (const oh of hierarchy) {
    // Root level
    if (oh.children === undefined || oh.children.length === 0) {
      if (tableIdx[oh.identifier] === undefined) {
        // omit
        continue
      }
      ret[oh.identifier] = {
        ...tableIdx[oh.identifier],
        segment: `${oh.displayName}`,
        sortKey: `${sortKeySuffix}${sortKey}`,
        isLeaf: !(oh?.children),
        indent
      }
    } else {
      // we recurse
      const recRes = f(tableIdx, oh.children, indent + 1, `${sortKeySuffix}${sortKey}`)

      ret = {
        ...recRes,
        ...ret
      }

      if (ret[oh.children[0].identifier] === undefined) {
        // Data is not always fully loaded
        continue
      }


      ret[oh.identifier] = {}

      const keys = Object.keys(ret?.[oh?.children[0]?.identifier] ?? {})
        .filter(k => k !== 'segment')
      for (const col of keys) {
        ret[oh.identifier][col] = oh.children
          ?.filter(p => ret[p.identifier] !== undefined) // Filter away the children that does not exist
          .map(p => ret[p.identifier][col])
          .reduce<TablePayload>((p, c) => ({
            "from_calls": p.from_calls + Number((c as TablePayload)?.from_calls),
            "from_ftes": p.from_ftes + Number((c as TablePayload)?.from_ftes),
            "to_calls": p.to_calls + Number((c as TablePayload)?.to_calls),
            "to_ftes": p.to_ftes + Number((c as TablePayload)?.to_ftes)
          }), {
            "from_calls": 0,
            "from_ftes": 0,
            "to_calls": 0,
            "to_ftes": 0
          })
      }

      ret[oh.identifier].indent = indent
      ret[oh.identifier].isLeaf = !(oh?.children)
      ret[oh.identifier].segment = `${oh.displayName}`
      ret[oh.identifier].sortKey = `${sortKeySuffix}${sortKey}`
    }

    sortKey += 1
  }

  return ret
}

/**
 * Applies org hiararchy to a set of predictions
 * 
 * This hook does a few things:
 * 1. Applies the nested hierarchy
 * 2. Applies display names
 * 3. Applies a sort key that makes sure that subhierarkies are not sorted under
 *    other elements
 * 
 * Notes: If predictions does not exist in hierarchy they are omitted.
 * 
 * @param p 
 * @param hierarchy 
 */
export const useApplyOrgHierarchy = f
