import { PhoneIcon, UsersIcon } from "@heroicons/react/outline"
import { useContext } from "react"
import { NotificationsSerializer } from "../../backend-types"
import { config } from "../../config"
import ErContext from "../../ErContext"
import { callsToResources } from "../../util"

const notificationFormatter = Intl.DateTimeFormat(config.locale, {
  day: "numeric",
  month: "short",
  year: "numeric"
})

const stringDict: { [k: string]: string } = {
  "DAY": "1st",
  "EVENING": "2nd",
  "OVERNIGHT": "3rd"
}

const UpdatedPredictionNotification = ({ active, notification, onClick }: { active: boolean, notification: NotificationsSerializer, onClick: () => void }) => {
  const context = useContext(ErContext)
  const callVolume = notification?.payload?.updated_calls
  const previousVolume = notification?.payload?.previous_calls
  const date = new Date(notification?.payload?.prediction_date)
  const shift = stringDict[notification?.payload?.shift ?? ""]
  const areaTitle = notification?.payload?.territory_display_name
  const resFactor = context.state.resourceFactor

  const seen = notification.notification_state === "SEEN"

  return (<div onClick={onClick} className={`pl-5 py-5 border-b-2 last:border-b-0 cursor-pointer ${active ? "bg-gray-50" : ""}`}>
    <div className="mx-5">
      <div className="relative right-6">
        <span className={`mr-4 ${seen ? "text-transparent" : "text-blue-500"}`}>●</span>
        <span className={`${!seen ? "text-blue-500" : ""} font-bold`}>Prediction Changed</span>
        <span className=""> – {notificationFormatter.format(date)} ({shift} Shift)</span>
      </div>
      <div className="font-bold text-gray-800">
        {areaTitle}
      </div>
      <div className="rounded-md border-2 mt-3 p-4">
        <div className="flex m-1 mb-2">
          <PhoneIcon className="w-5 h-5 mr-2" />
          <div className="w-44">
            Call Volume
          </div>
          <div>
            : <span className="text-green-500 font-bold">{callVolume}</span> (was {previousVolume})
          </div>
        </div>
        <div className="flex m-1">
          <UsersIcon className="w-5 h-5 mr-2" /><div className="w-44">Resource Allocation</div>
          <div>
            : <span className="font-bold">{callsToResources(callVolume, resFactor)}</span>
            {callsToResources(callVolume, resFactor) !== callsToResources(previousVolume, resFactor) ? `(was ${callsToResources(previousVolume, resFactor)})` : ""}
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default UpdatedPredictionNotification