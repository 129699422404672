import { useContext } from 'react';
import { useQuery } from 'react-query';
import { } from '../backend-types';
import { config } from '../config';
import ErContext, { UserInfo } from '../ErContext';

export const userInfoFetcher = (authToken: string) => async () => {

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);

  const request = await fetch(`${config.backendBaseUrl}/api/user_info/`, {
    headers
  })
  return await request.json() as UserInfo
}

export const useGetUserInfo = (): UserInfo | undefined => {
  const { state: { authToken } } = useContext(ErContext)
  const { isLoading, error, data } = useQuery(['userInfo'], userInfoFetcher(authToken!), { refetchOnWindowFocus: false })

  if (isLoading || error || data === undefined) {
    return undefined
  }

  return data
}

