import { Switch } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'

type SwitchProps = {
  isOn: boolean
  setOn: (v: boolean) => void
  text: string
}

const SwitchCmp = ({ isOn, setOn, text, ...props }: SwitchProps) => {
  return (<Switch.Group>
    <div className="flex items-center"></div>
    <Switch
      {...props}
      checked={isOn}
      onChange={setOn}
      className={`transform transition ease-in-out duration-200 ${isOn ? 'bg-blue-400 border-blue-600' : 'bg-gray-200 border-gray-400'
        } relative inline-flex items-center h-6 rounded-full w-9 border`}
    >
      <span className="sr-only">{text}</span>
      <span
        className={`transform transition ease-in-out duration-200 ${isOn ? 'translate-x-4' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full`}
      >
        <CheckIcon className={`${isOn ? "opacity-100" : "opacity-0"} transition-opacity duration-200 text-blue-400`} />

      </span>
    </Switch>
    <Switch.Label className="mr-4 ml-2">{text}</Switch.Label>
  </Switch.Group>)
}

export default SwitchCmp