import { Disclosure } from "@headlessui/react"
import { ChevronRightIcon } from "@heroicons/react/outline"
import { useMemo, useState } from "react"
import { useFlexLayout, useGlobalFilter, useTable } from "react-table"
import Arrow from "../components/Arrow"
import BottomBar from "../components/BottomBar"
import NavBar from "../components/NavBar"
import PredictionsTable from "../components/PredictionsTable"
import TitleBar from "../components/TitleBar"
import ToggleBar from "../components/ToggleBar"
import { config } from "../config"
import { useApplyOrgHierarchy } from "../hooks/useApplyOrgHierarchy"
import { useGetModelRuns } from "../hooks/useGetModelRuns"
import { useGetPredictionsIdx } from "../hooks/useGetPredictionsIdx"
import { useGetOrgTree } from "../hooks/useOrgTree"
import { useTablePager } from "../hooks/useTablePager"
import { isTablePayload } from "../types"
import { range } from "../util"

const holidays = (() => {
  const h: { title: string, date: string }[] = require("./../public-holidays-us.json")
  const idx: { [date: string]: string } = {}

  h.forEach(i => {
    idx[i.date] = i.title
  })

  return (date: Date) => idx[date.toISOString().split('T')[0]]
})()

const wekkDayFormatter = new Intl.DateTimeFormat(config.locale, {
  weekday: "long"
});
const dayFormatter = new Intl.DateTimeFormat(config.locale, {
  day: "numeric"
});

const SegmentCell = (p: any) => {
  const indent = p?.row?.original?.indent ?? 0
  const isLeaf = p?.row?.original?.isLeaf

  const [toggled, setToggled] = useState(true)
  return (
    <div className="w-full h-full flex justify-between items-center px-4 py-2">
      <span className={`${indent === 1 ? 'pl-7' : indent === 2 ? 'pl-14' : ""}`}>
        {!isLeaf ? <Disclosure.Button onClickCapture={() => setToggled(!toggled)}>
          <ChevronRightIcon
            className={`w-4 h-4 mr-1 ${toggled ? "transform rotate-90" : ""} transition-transform`}
          />
        </Disclosure.Button> : <span className="mr-1">●</span>}
        {`${p?.value}`}
      </span>
    </div>
  )
}

const DateHeader = (today: Date, thatDay: Date) => (<>
  <span className={`font-bold mr-2 ${thatDay.getDate() === today.getDate() ? "bg-blue-600 rounded-md p-1 text-white" : ""}`}>
    {dayFormatter.format(thatDay)}
  </span>
  <span className={`${thatDay.getDate() === today.getDate() ? "text-blue-600 " : ""}`}>{wekkDayFormatter.format(thatDay)}</span>
  <br />
  <div className="overflow-hidden text-green-700 truncate w-full h-5 -mt-1 text-xs">
    {holidays(thatDay)}
  </div>
</>)

const stringDict: { [key: string]: string } = {
  "shift-0": "1st",
  "shift-1": "2nd",
  "shift-2": "3rd",
  "total": "Total"
}

const Cell = (showFtes: boolean) => (p: any) => {
  const prev = showFtes ? p?.value?.from_ftes : p?.value?.from_calls
  const curr = showFtes ? p?.value?.to_ftes : p?.value?.to_calls
  return (<div data-for='cell-tooltip' data-tip={`${JSON.stringify(p?.value)}`} className={`w-full h-full flex justify-between items-center px-4 py-2 border-2 border-transparent hover:border-blue-300`}>
    {isNaN(curr) ? <><span></span> -</> : (<><Arrow from={prev} to={curr} /> {curr}</>)}
  </div>)
}

const PredictionsScreen = () => {
  const [showShifts, setShowShifts] = useState(false)
  const [showFtes, setShowFtes] = useState(false)
  const [horizontalPage, flipPage, maxPage, setMaxPage] = useTablePager()
  const modelRuns = useGetModelRuns()
  const latestRun = modelRuns[0]
  const orgTree = useGetOrgTree()
  const { data: predIdx, isLoading } = useGetPredictionsIdx(modelRuns[1], latestRun)
  const predictions = useApplyOrgHierarchy(predIdx, orgTree)
  const predictionsList = Object.values(predictions).sort((a, b) => (a?.sortKey as string)?.localeCompare(b?.sortKey as string))

  // We define accessors such that we have segment and date-[0 .. 14]-(shift-[0, 1, 2] | total)
  // When rendering the full table, we use use shifts data, when rendering
  // the totals-only table we remove accessors for the shifts
  const columns = useMemo(
    () => {
      const today = new Date()

      const segmentCol = showShifts ? {
        Header: <></>,
        id: "segment-header",
        accessor: "segment-header",
        showFilter: true,
        columns: [{
          Header: () => <div className="w-full font-bold text-right">Shift</div>,
          id: "segment",
          accessor: "segment",
          Cell: SegmentCell,
          width: 350
        }],

      } : {
        Header: <></>,
        id: "segment",
        accessor: "segment",
        showFilter: true,
        Cell: SegmentCell,
        width: 350
      }

      return [
        segmentCol,
        // We are using the invariant that the table always shows 14 days ahead
        // starting today.
        ...range(0, 14).map(relDay => { // Map over relative days
          const thatDay = new Date()
          thatDay.setDate(today.getDate() + relDay)

          const ret: any = {
            Header: DateHeader(today, thatDay),
            id: `date-${relDay}`,
            Cell: Cell(showFtes),
            width: 150,
            height: 20
          }

          if (showShifts) {
            const lastModelRun = new Date(latestRun)
            ret.columns = ["shift-0", "shift-1", "shift-2", "total"]
              .filter(shift => shift === "shift-0" && relDay === 0 && lastModelRun.getHours() >= 15 && lastModelRun.getDay() === today.getDay() ? false : true)
              .map(shift => ({
                Header: (p) => <span className="font-bold">{stringDict[shift] ?? ""}</span>,
                accessor: `date-${relDay}-${shift}`,
                Cell: Cell(showFtes),
                width: 100,
                height: 20
              }))
          } else {
            ret.accessor = `date-${relDay}-total`
          }

          return ret
        })]
    },
    [showShifts, showFtes, latestRun]
  )

  const tableInstance = useTable({
    columns,
    data: predictionsList,
    autoResetGlobalFilter: false
  }, useGlobalFilter, useFlexLayout)

  /**
   * Render CSV file
   * 
   * Function that render current view into a CSV string
   */
  const renderCsv = (): string => {
    const today = new Date()

    // Header
    let resultString = tableInstance.allColumns
      .map(c => c.id)
      .map(id => {
        if (id === "segment") return `"Territory"`
        const parts = id.split("-")

        const thatDay = new Date()
        thatDay.setDate(today.getDate() + Number(parts[1]))

        const holiday = holidays(thatDay) ? ` (${holidays(thatDay)})` : ""

        return `${thatDay.toLocaleDateString()} ${parts[2] ? stringDict[parts.slice(2).join("-")] : ""}${holiday}`
      })
      .join(", ")
    resultString += "\n"


    // Body
    tableInstance.rows.forEach(r => {
      tableInstance.prepareRow(r)
      resultString += r.cells.map(c => `${isTablePayload(c?.value) ? showFtes ? c?.value.to_ftes : c?.value.to_calls : c?.value ?? ""}`).join(", ")
      resultString += "\n"
    })

    return resultString
  }

  return (
    <>
      <NavBar></NavBar>
      <div className="flex-none w-full h-20 flex justify-between py-7">
        <div className="mx-5 flex-none"><TitleBar maxPage={maxPage} page={horizontalPage} flipPage={flipPage}></TitleBar></div>
        <div className="mx-5 flex-none"><ToggleBar showShifts={showShifts} setShowShifts={setShowShifts} showFtes={showFtes} setShowFtes={setShowFtes} renderCsv={renderCsv}></ToggleBar></div>
      </div>
      <PredictionsTable isLoading={isLoading} maxPage={maxPage} flipPage={flipPage} tableInstance={tableInstance} predictions={predictions} showShifts={showShifts} horizontalPage={horizontalPage} setMaxPage={setMaxPage}></PredictionsTable>
      <BottomBar predictionDate={latestRun ? new Date(latestRun) : undefined}></BottomBar>
    </>
  )
}

export default PredictionsScreen