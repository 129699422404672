import { NotificationsSerializer } from "../../backend-types"
import { config } from "../../config"


const notificationFormatter = Intl.DateTimeFormat(config.locale, {
  day: "numeric",
  month: "short",
  year: "numeric"
})

const UpdatedResourceFactor = ({ active, notification, onClick }: { active: boolean, notification: NotificationsSerializer, onClick: () => void }) => {
  const payload = notification.payload
  const prevFactor = payload?.previous_factor
  const newFactor = payload?.new_factor
  const date = new Date(payload?.updated_date)

  const seen = notification.notification_state === "SEEN"

  return (<div onClick={onClick} className={`pl-5 py-5 border-b-2 last:border-b-0 cursor-pointer ${active ? "bg-gray-50" : ""}`}>
    <div className="mx-5">
      <div className="relative right-6">
        <span className={`mr-4 ${seen ? "text-transparent" : "text-blue-500"}`}>●</span>
        <span className={`${!seen ? "text-blue-500" : ""} font-bold`}>Updated Resource Calculation</span>
        <span className=""> – {notificationFormatter.format(date)}</span>
      </div>
      <div className="rounded-md border-2 mt-3 p-4">
        <div className="flex m-1 mb-2">
          <div className="w-44">
            Previous factor
          </div>
          <div>
            : <span className="font-bold">{prevFactor}</span>
          </div>
        </div>
        <div className="flex m-1">
          <div className="w-44">
            New factor
          </div>
          <div>
            : <span className="font-bold">{newFactor}</span>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default UpdatedResourceFactor