import Keycloak from "keycloak-js";
import { config } from "../config";
import { TErContext, UserInfo } from "../ErContext";
import { userInfoFetcher } from "../hooks/useGetUserInfo";


/**
 * Initializes the pendo client using the `user` information to configure
 * what is sent to pendo about the current user.
 */
export const initPendo = (user: UserInfo) => {

  const env = config.env
  const id = config.realm
  const clientId = `emergency-response-${config.realm}`

  pendo.initialize({
    visitor: {
      appRoles: JSON.stringify(user?.permissions),
      email: user.email ?? "",
      full_name: user.name,
      id: user.id,
      // isAppAdmin: user.isAppAdmin,
      // realmRoles: user.realmRoles,
      // roles: user.appRoles[clientId]?.roles ?? [],
    },
    account: { id, env, clientId },
  })
}

export class AuthService {

  private _keycloak = Keycloak({
    url: config.kcUrl,
    realm: config.realm,
    clientId: config.kcClientId
  })

  private _clearInteral = () => { }

  async initialize(ctx: TErContext) {
    await this._keycloak.init({
      onLoad: "login-required",
      redirectUri: window.location.href,
    })

    const handle = setInterval(() => {
      this._keycloak.updateToken(5).then((refreshed) => {
        if (refreshed) {
          ctx.actions.setAuthToken(this._keycloak.token!)
        }
      }).catch(this.logout);
    }, 2500)

    this._clearInteral = () => clearInterval(handle)

    const token = this._keycloak.token
    if (!token) {
      console.error("Trouble loggin in")
      return
    }
    ctx.actions.setAuthToken(token)

    const uInfo = await userInfoFetcher(token)()

    ctx.actions.setUser(uInfo)

    initPendo(uInfo)
  }

  logout() {
    this._clearInteral()
    this._keycloak.logout({
      redirectUri: window.location.href
    })
  }
}