import { useState } from "react"

export const useTablePager = (): [number, (deltaPage: "left" | "right" | "home") => void, number, (m: number) => void] => {
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(1)

  const flipPage = (deltaPage: "left" | "right" | "home") => {
    if (deltaPage === "left" && page > 0) {
      setPage(page - 1)
    }
    else if (deltaPage === "right" && page < maxPage) {
      setPage(page + 1)
    }
    else if (deltaPage === "home") {
      setPage(0)
    }
  }

  const setMax = (n: number) => {
    setMaxPage(n)
    if (page > n) {
      setPage(n)
    }
  }

  return [page, flipPage, maxPage, setMax]
}