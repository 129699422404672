import { useContext } from 'react';
import { useQuery } from 'react-query';
import { OrganizationSerializer } from '../backend-types';
import { config } from '../config';
import ErContext from '../ErContext';

/*******************************************
 *** Constants
 ******************************************/

const oranizationIdent = () => "organization"

/*******************************************
 *** Fetcher
 ******************************************/

const fetchOrganization = (authToken: string) => async () => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);
  return await (await fetch(`${config.backendBaseUrl}/api/organization/latest/`, {
    headers
  })).json()
}

/*******************************************
 *** Export
 ******************************************/

export const useGetOrgTree = () => {
  const { state: { authToken } } = useContext(ErContext)
  const { data } = useQuery<OrganizationSerializer | undefined>(oranizationIdent(), fetchOrganization(authToken!))

  return data?.org_hierarchy as (OrgTree | undefined)
}

