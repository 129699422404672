declare global {
  interface Window { __env: { [key: string]: string }; }
}

const extCfg = window.__env

export const config = {
  // App stuff
  locale: "en-US",
  backendBaseUrl: extCfg?.["BACKEND_BASE_URL"] ?? "NA",
  env: (extCfg?.["ER_ENV"] ?? "development").toLocaleLowerCase(),
  deploymentName: (extCfg?.["DEPLOYMENT_NAME"] ?? "Development").toLocaleLowerCase(),
  realm: extCfg?.["KC_REALM"] ?? 'urbinternal',

  // Keycloak stuff
  kcUrl: extCfg?.["KC_URL"] ?? "NA",
  kcClientId: extCfg?.["KC_CLIENT_ID"] ?? 'NA',
}
