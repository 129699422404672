import { AuthService } from "./auth"

class Factory {

  private _authService = new AuthService()

  authService () {
    return this._authService
  }
}

export const factory = new Factory()