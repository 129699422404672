import { useContext } from 'react';
import { useQuery } from 'react-query';
import { NotificationsSerializer } from '../backend-types';
import { config } from '../config';
import ErContext from '../ErContext';

const notificationFetcher = authToken => async () => {

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authToken}`);

  let results: NotificationsSerializer[] = []
  let page = 1



  while (true) {
    const request = await fetch(`${config.backendBaseUrl}/api/notifications/?page=${page}`, {
      headers
    })

    if (request.status !== 200) {
      break;
    }

    page += 1

    const json = await request.json()
    results = results.concat(json.results)

    if (json.next === null) {
      break
    }

    // A hacky way to quickly only fetch first page
    break
  }

  return results
}

export const useGetNotifications = (lastDismissed: number) => {
  const { state: { authToken } } = useContext(ErContext)
  const { isLoading, error, data } = useQuery(['notifications', lastDismissed], notificationFetcher(authToken), { refetchOnWindowFocus: false })

  if (isLoading || error || data === undefined) {
    return []
  }

  return data
}

