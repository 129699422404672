import { Menu, Transition } from '@headlessui/react'
import { DotsHorizontalIcon } from '@heroicons/react/outline'
import { Fragment, useContext, useState } from 'react'
import { config } from '../config'
import ErContext from '../ErContext'
import { hasPermissionTo } from '../util'
import ResourceAllocationModal from './ResourceAllocationModal'
import Switch from "./Switch"


type ToggleBarProps = {
  showShifts: boolean
  setShowShifts: (v: boolean) => void
  showFtes: boolean
  setShowFtes: (v: boolean) => void
  renderCsv: () => string
}

const ToggleBar = ({ showShifts, setShowShifts, renderCsv, showFtes, setShowFtes }: ToggleBarProps) => {
  const { state: { userInfo } } = useContext(ErContext)
  const [open, setOpen] = useState(false)

  const downloadExport = () => {
    const data = renderCsv()

    const formatter = Intl.DateTimeFormat(config.locale, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })

    // Deprecation notice is on the node implementation, this is run in the browser
    const base64 = `data:text/csv;base64,${btoa(data)}`;
    const a = document.createElement('a')

    const type = !showFtes ? `call_predictions` : `fte_predictions`

    a.download = `urbint_er_${type}_${formatter.format(new Date())}.csv`
    a.href = base64
    a.target = "_blank"
    a.dispatchEvent(new MouseEvent('click'))
  }

  return (
    <div className="flex">
      <Switch data-feature-id="er-show-fte-count" setOn={setShowFtes} isOn={showFtes} text="Show FTE count" />
      <Switch data-feature-id="er-show-shift-level" setOn={setShowShifts} isOn={showShifts} text="Show shift level" />

      <Menu as="div" className="ml-6 mr-8">
        <div>
          <Menu.Button className="active:bg-gray-200 active:shadow-inner px-2 rounded-md">
            <DotsHorizontalIcon className="inline w-4 h-4" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-50 absolute right-10 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item onClick={downloadExport}>
              {({ active }) => (
                <span data-feature-id="er-download-export" className={`block px-4 py-2 text-sm text-gray-700 cursor-pointer ${active ? 'bg-gray-100' : ''}`}>
                  Download export
                </span>
              )}
            </Menu.Item>
            <Menu.Item onClick={() => setOpen(true)} disabled={!hasPermissionTo(userInfo, "EDIT_RESOURCE_FACTOR")}>
              {({ active }) => (
                // Note: The empty span is needed for headless UI not taking over
                // control of the ID field. see docs/Pendo.md
                <span>
                  <span data-feature-id="er-modify-resources" className={`block px-4 py-2 text-sm text-gray-700 cursor-pointer ${active ? 'bg-gray-100' : ''} ${!hasPermissionTo(userInfo, "EDIT_RESOURCE_FACTOR") ? "text-gray-300" : ""}`}>
                    Modify resource allocation
                  </span>
                </span>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <ResourceAllocationModal setOpen={setOpen} open={open} />
    </div>
  )
}

export default ToggleBar